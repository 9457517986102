<template>
  <!-- prettier-ignore -->
  <div
    class="filter"
    @click="search"
  >
    <basic-text-input
      ref="inputRef"
      v-model="selectedLabel"
      manual-validation
      :name="`input-${$props.name}`"
      required
      :label="$props.label"
      :size="$props.size"
      :supporting-text="$props.supportingText"
      :v="$props.v"
      @input.stop="search"
      @keydown="onKeydown"
    >
      <template #trailing>
        <the-icon
          art="light"
          :name="dropdownRef?.isOpen ? 'chevron-up' : 'chevron-down'"
        />
      </template>
    </basic-text-input>

    <basic-dropdown-menu
      :id="dropdownId"
      ref="dropdownRef"
      manual-focus
      :name="`menu-${$props.name}`"
      :filterable="true"
      :options="results"
      :selected="model"
      :size="$props.size"
      :trigger="inputRef?.inputTriggerRef"
      @select="selectItem"
    />
  </div>
</template>

<script setup>
import { nextTick, ref, reactive, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { ReactivityUtil } from '@/utils/Reactivity'

import BasicDropdownMenu from '@/components/Basic/DropdownMenu'
import BasicTextInput from '@/components/Basic/TextInput'

// INIT
const model = defineModel({ type: String })
const props = defineProps({
  label: {
    type: String,
    required: true,
  },

  name: {
    type: String,
    required: true,
  },

  options: {
    type: Array,
    required: true,
  },

  size: {
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'sm',
  },

  supportingText: {
    type: String,
    default: undefined,
  },

  v: {
    type: Object,
    default: () => {},
  },
})

// DATA
const KEY = uuidv4()
const dropdownId = `dropdown-${KEY}`
const dropdownRef = ref(null)
const selectedLabel = ref(null)
const inputRef = ref(null)

const results = reactive([])

// METHODS
function selectItem(item) {
  selectedLabel.value = item.label
  model.value = item.value

  inputRef.value.inputRef.focus()
}

async function search() {
  ReactivityUtil.resetData(results)

  await nextTick()

  const searchList = filterResults(selectedLabel.value)
  ReactivityUtil.reAssign(results, searchList)

  if (results.length > 0) {
    // Multiple results
    dropdownRef.value.open()
  }
}

function filterResults(searchTerm) {
  if (!searchTerm) return props.options

  const sanitizedSearchTerm = searchTerm.replace('(', '\\(').replace(')', '\\)').replace('+', '\\+')
  const searchRegex = new RegExp(`^${sanitizedSearchTerm}`, 'i')
  const filteredResults = props.options.filter(option => {
    return option.label.match(searchRegex)
  })

  // no results
  if (filteredResults.length === 0) {
    model.value = null
  }

  return filteredResults
}

function onKeydown(event) {
  if (dropdownRef.value.isOpen) {
    switch (event.keyCode) {
      case 9: // tab
      case 38: // up
      case 40: // down
        dropdownRef.value.setFocus()
        event.preventDefault()
        break

      case 13: // enter
        selectItem(results[0])
        dropdownRef.value.close()
        event.preventDefault()
        break
    }
  }
}

// WATCHERS
watch(
  model,
  value => {
    if (value && selectedLabel.value !== value) {
      selectedLabel.value = props.options.find(x => x.value === value)?.label
    }
  },
  { immediate: true }
)

watch(
  () => selectedLabel.value,
  value => {
    if (value === null) {
      // model has been cleared in textinput, therefore clear the model here as well
      model.value = null
      ReactivityUtil.reAssign(results, props.options)
      dropdownRef.value.open()
    }
  }
)
</script>

<style scoped>
.filter {
  position: relative;
  display: inline-block;
  margin: 0 0 var(--dotcom-responsive-spacing-res-xxxs);
  width: 100%;
}
</style>
